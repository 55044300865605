import defaultArt from '../assets/images/dj_kip_art.png';

export function getImg(imgUrl) {
  if (!imgUrl) return;

  let img = defaultArt;
  if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
    img = `${
      import.meta.env.VITE_REACT_APP_BASE_CLIENT_URL
    }/src/assets/${imgUrl}`;
  } else {
    img = `${import.meta.env.VITE_REACT_APP_BASE_CLIENT_URL}/${imgUrl}`;
  }
  return img;
}

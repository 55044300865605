import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import LoadingScreen from '../components/LoadingScreen';
import {
  Button,
  Link,
  Typography,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  Stack,
} from '@mui/material';
import { SignIn } from '@clerk/clerk-react';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const { login } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    setErrors('');
    const { uname, pass } = document.forms[0];

    const loginResult = await login(uname.value, pass.value);

    setLoading(false);

    if (loginResult === 'fail') {
      setErrors(
        `We don't see an account that matches that username/password. If you need to reset your password, please use the link below.`
      );
      return;
    }
    if (loginResult === 'error') {
      setErrors('Something happened, please try again later.');
      return;
    }
    if (loginResult) {
      let next = '/';
      if (location.state && location.state.next) {
        next = location.state.next;
      }
      navigate(next === '/' ? '/home' : next);
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Box
        sx={{
          width: '100%',
        }}
        color={'white'}
      >
        <Typography variant="h5" mb={3}>
          Glad to have you back,
          <br />
          let&apos;s dive into some new music!
        </Typography>

        <SignIn
          appearance={{
            elements: {
              rootBox: { width: '100%' },
              cardBox: { width: '100%' },
              header: { display: 'none' },
              footer: { display: 'none' },
              card: {
                padding: '4px',
                marginTop: '12px',
                backgroundColor: '#000',
              },
              button: {
                borderColor: '#eee',
                borderWidth: '1px !important',
                borderStyle: 'solid',
                height: '50px',
                backgroundColor: '',
                padding: '6px 0px',
              },
              socialButtonsIconButton__apple: {
                '.cl-providerIcon__apple': { filter: 'invert(1)' },
              },
            },
          }}
        />

        <Typography mt={2} variant="body1" textAlign={'center'}>
          Or continue with
        </Typography>

        <form onSubmit={onLogin} autoComplete="off">
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            name="uname"
            type="text"
            inputProps={{
              autoComplete: 'off',
              autoCapitalize: 'off',
            }}
            required
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type="password"
            margin="normal"
            name="pass"
            inputProps={{
              autoComplete: 'off',
              autoCapitalize: 'off',
            }}
            required
          />
          {errors && (
            <Typography color="error" variant="body2">
              {errors}
            </Typography>
          )}
          <Stack spacing={2} mt={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={isMobile}
              sx={{ marginTop: 2, minWidth: '80px' }}
            >
              Log In
            </Button>
            <Typography variant="body2">
              <Link
                to="/forgot-password"
                href="/forgot-password"
                ml={1}
                underline="hover"
              >
                Forgot your password?
              </Link>
            </Typography>
          </Stack>
        </form>

        <Box mt={3}>
          <Typography mt={2} variant="body2">
            Don&apos;t have an account yet?
            <Link to="/register" href="/register" ml={1} underline="hover">
              Register
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Login;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { useApi } from '../context/ApiProvider';
import {
  Button,
  Link,
  Typography,
  TextField,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SignIn } from '@clerk/clerk-react';

const Register = () => {
  const [unameError, setUnameError] = useState('');
  const [passError, setPassError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSignup = async (e) => {
    e.preventDefault();
    setUnameError('');
    setPassError('');
    setEmailError('');
    const { uname, email, pass, about } = document.forms[0];

    if (pass.value.length < 3) {
      setPassError('Password must have at least 3 characters');
      return;
    }

    const userInfo = {
      username: uname.value,
      email: email.value,
      password: pass.value,
      about_me: about.value,
    };
    setLoading(true);
    const signupResult = await api.post('/users', userInfo);

    if (signupResult.ok) {
      navigate('/login');
    } else {
      const errors = signupResult.body?.errors?.json;
      if (errors.username) {
        setUnameError(
          'This username is already taken, please use a different one'
        );
      }
      if (errors.email) {
        setEmailError(
          'This email is already taken, please use a different one'
        );
      }
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingScreen />}

      <Box sx={{ width: '100%' }} color={'white'}>
        <Typography variant="h5" mb={3}>
          Welcome to KIP
        </Typography>

        <SignIn
          appearance={{
            elements: {
              rootBox: { width: '100%' },
              cardBox: { width: '100%' },
              header: { display: 'none' },
              footer: { display: 'none' },
              card: {
                padding: '4px',
                marginTop: '12px',
                backgroundColor: '#000',
              },
              button: {
                borderColor: '#eee',
                borderWidth: '1px !important',
                borderStyle: 'solid',
                height: '50px',
                backgroundColor: '',
                padding: '6px 0px',
              },
              socialButtonsIconButton__apple: {
                '.cl-providerIcon__apple': { filter: 'invert(1)' },
              },
            },
          }}
        />
        <Typography mt={2} variant="body1" textAlign={'center'}>
          Or continue with
        </Typography>
        <form onSubmit={onSignup} autoComplete="off">
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            name="uname"
            required
            inputProps={{
              autoComplete: 'off',
              autoCapitalize: 'off',
            }}
            error={unameError !== ''}
          />

          {unameError && (
            <Typography color="error" variant="body2">
              {unameError}
            </Typography>
          )}

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            margin="normal"
            name="email"
            autoComplete="off"
            required
            error={emailError !== ''}
          />

          {emailError && (
            <Typography color="error" variant="body2">
              {emailError}
            </Typography>
          )}

          <TextField
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            name="pass"
            autoComplete="off"
            required
            error={passError !== ''}
          />

          {passError && (
            <Typography color="error" variant="body2">
              {passError}
            </Typography>
          )}

          <TextField
            label="About"
            variant="outlined"
            fullWidth
            margin="normal"
            name="about"
            autoComplete="off"
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth={isMobile}
            sx={{ marginTop: 2 }}
            disabled={loading}
          >
            Register
          </Button>
          <Box mt={3}>
            <Typography mt={2} variant="body2">
              Do you already have an account?
              <Link to="/login" href="/login" ml={1} underline="hover">
                Log In
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Register;

export default function loadMp3File(mp3_permalink) {
  if (!mp3_permalink) return false;

  let mp3File = null;
  if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
    mp3File = `${
      import.meta.env.VITE_REACT_APP_BASE_CLIENT_URL
    }/src/assets/${mp3_permalink}`;
  } else {
    mp3File = `${
      import.meta.env.VITE_REACT_APP_BASE_CLIENT_URL
    }/${mp3_permalink}`;
  }
  return mp3File;
}

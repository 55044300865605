import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import PropTypes from 'prop-types';
import LoadingScreen from './LoadingScreen';
import { useUser as useClerkUser, useAuth } from '@clerk/clerk-react';
import { useApi } from '../context/ApiProvider';

function PrivateRoute({ children }) {
  const api = useApi();
  const { user: clerkUser } = useClerkUser();
  const { user: kipUser, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuth();

  async function getTokens() {
    const token = await getToken();
    return token;
  }

  async function createClerkUser() {
    setLoading(true);
    const userInfo = {
      clerk_id: clerkUser.id,
      username:
        clerkUser.username || clerkUser.primaryEmailAddress.emailAddress,
      email: clerkUser.primaryEmailAddress.emailAddress,
      password: 'password',
      about_me: `${clerkUser.firstName} ${clerkUser.lastName}`,
    };

    const existingUserResponse = await api.get('/users', {
      search: userInfo.email,
      order_by: 'email',
      order_direction: 'asc',
    });

    let userObj = {};
    if (existingUserResponse.ok) {
      const existingUser = existingUserResponse.body.data[0];
      if (existingUser.clerk_id !== clerkUser.id) {
        const updateUserResponse = await api.put('/me', {
          ...existingUser,
          clerk_id: clerkUser.id,
        });
        if (updateUserResponse.ok) {
          localStorage.setItem('accessToken', getTokens());
        } else {
          const errors = updateUserResponse.body?.errors?.json;
          console.log(errors);
        }
      }
      userObj = existingUser;
    } else {
      const signupResult = await api.post('/users', userInfo);
      if (signupResult.ok) {
        userObj = signupResult.body;
        localStorage.setItem('accessToken', getTokens());
      } else {
        const errors = signupResult.body?.errors?.json;
        console.log(errors);
      }
    }
    setUser(userObj);
    setLoading(false);
  }

  useEffect(() => {
    if (clerkUser) {
      createClerkUser();
    }
  }, [clerkUser]);

  const location = useLocation();

  if (loading) {
    return <LoadingScreen />;
  }

  if ((clerkUser || kipUser) !== null) {
    return children;
  }

  const url = location.pathname + location.search + location.hash;
  return <Navigate to="/login" state={{ next: url }} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PrivateRoute;

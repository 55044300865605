const BASE_API_URL = import.meta.env.VITE_REACT_APP_BASE_API_URL;
// const BASE_API_URL = 'https://api.djkip.com'; //uncomment this to connect to prod BE

import { trigger } from './utils/events';

function getCookie(name) {
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (key === name) {
      return decodeURIComponent(value);
    }
  }
  return null; // Return null if cookie not found
}

export default class ApiClient {
  constructor() {
    this.base_url = BASE_API_URL + '/api';
  }

  async request(options) {
    let response = await this.requestInternal(options);
    if (response.status === 401 && options.url !== '/tokens') {
      const token = getCookie('__session');
      const refreshResponse = await this.put('/tokens', {
        access_token: token || localStorage.getItem('accessToken'),
        refresh_token: token || localStorage.getItem('accessToken'),
      });

      if (refreshResponse.ok) {
        localStorage.setItem('accessToken', refreshResponse.body.access_token);
        localStorage.setItem(
          'refreshToken',
          refreshResponse.body.refresh_token
        );
        response = await this.requestInternal(options);
      } else {
        this.logout();
      }
    }
    return response;
  }

  async requestInternal(options) {
    let query = new URLSearchParams(options.query || {}).toString();
    if (query !== '') {
      query = '?' + query;
    }

    let response;
    const token = getCookie('__session') || localStorage.getItem('accessToken');
    try {
      response = await fetch(this.base_url + options.url + query, {
        method: options.method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          ...options.headers,
        },
        credentials: options.url === '/tokens' ? 'include' : 'omit',
        body: options.body ? JSON.stringify(options.body) : null,
      });
    } catch (error) {
      response = {
        ok: false,
        status: 500,
        json: async () => {
          return {
            code: 500,
            message: 'The server is unresponsive',
            description: error.toString(),
          };
        },
      };
    }

    return {
      ok: response.ok,
      status: response.status,
      body: response.status !== 204 ? await response.json() : null,
    };
  }

  async get(url, query, options) {
    return this.request({ method: 'GET', url, query, ...options });
  }

  async post(url, body, options) {
    return this.request({ method: 'POST', url, body, ...options });
  }

  async put(url, body, options) {
    return this.request({ method: 'PUT', url, body, ...options });
  }

  async delete(url, options) {
    return this.request({ method: 'DELETE', url, ...options });
  }

  async login(username, password) {
    const response = await this.post('/tokens', null, {
      headers: {
        Authorization: 'Basic ' + btoa(username + ':' + password),
      },
    });
    if (!response.ok) {
      return response.status === 401 ? 'fail' : 'error';
    }
    localStorage.setItem('accessToken', response.body.access_token);
    localStorage.setItem('refreshToken', response.body.refresh_token);
    return 'ok';
  }

  async logout() {
    await this.delete('/tokens');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    trigger('logout');
  }

  isAuthenticated() {
    return localStorage.getItem('accessToken') !== null;
  }
}
